const getTitle = () => {
    let title = "Thea Web";
    if (process.env.REACT_APP_TESTING_SERVER) {
        title += ' TEST';
        if (process.env.REACT_APP_COMMIT_SHA) {
            title += ` (${process.env.REACT_APP_COMMIT_SHA.substr(0, 8)})`
        }
    } else {
        title += ` (v${process.env.REACT_APP_VERSION})`;
    }
    return title;
};

export default getTitle;