import {createStore} from 'redux';
import {persistStore, persistReducer, createTransform} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

// @ts-ignore
import JSOG from 'jsog'

import reducer from "./reducer";

// firebase contains cyclic references - we use JSOG for serialization and deserialization for the local storage persistence
export const JSOGTransform = createTransform(
    (inboundState) => JSOG.stringify(inboundState),
    (outboundState) => JSOG.parse(outboundState),
);

const persistConfig = {
    key: 'root',
    storage,
    transforms: [JSOGTransform],
    stateReconciler: hardSet,
    blacklist: ['firebase']
};

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, reducer);

const initStore = () => {
    let store = createStore(persistedReducer);
    let persistor = persistStore(store);

    return {store, persistor};
};

export default initStore;