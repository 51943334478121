import initialState from "../initialState";
import {SET_FIREBASE_INSTANCE, SET_FIREBASE_INITIALIZED} from "../actions";

import StateType from "../StateType";
import {default as Firebase} from "firebase/app";

type Action = {
    type: string,
    firebase?: any,
    firebaseUser?: Firebase.User | null
}

const firebaseReducer = (state: StateType = initialState, action: Action) => {
    switch (action.type) {
        case SET_FIREBASE_INSTANCE:
            return {...state, firebase: action.firebase};
        case SET_FIREBASE_INITIALIZED:
            return {...state, firebaseInitialized: true};
        default:
            return state;
    }
};

export default firebaseReducer;