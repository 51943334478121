import StateType from "./StateType";

const initialState: StateType = ({
    loggedIn: false,
    user: undefined,
    loginRedirect: undefined,
    firebase: undefined,
    firebaseInitialized: false
});

export default initialState;