import React, {memo} from "react";

import {Block} from "baseui/block";
import {useStyletron} from "baseui";

import LargeSpinner from "../LargeSpinner";

export default memo<{}>(() => {
    const [css, theme] = useStyletron();

    const containerStyle = css({
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'fixed',
        textAlign: 'center',
        paddingTop: `50vh`
    });

    const spinnerStyle = css({
        position: 'relative',
        margin: 'auto',
        top: `-${theme.sizing.scale1200}`
    });

    return (
        <Block className={containerStyle}>
            <LargeSpinner className={spinnerStyle}/>
        </Block>
    )
});