import React, {lazy, memo, useEffect} from 'react';
import {connect} from "react-redux";

import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import {setLoginRedirect, unsetLoginRedirect} from "../redux/actions";

const LoggedOutView = lazy(() => import('../views/LoggedOutView'));
const LoggedInView = lazy(() => import('../views/LoggedInView'));

interface Props {
    loggedIn: boolean,
    loginRedirect: string,
    dispatch: Function
}

const LoginLogoutRouter = ({loggedIn, loginRedirect, dispatch}: Props) => {
    useEffect(() => {
        if (loggedIn) {
            dispatch(unsetLoginRedirect());
        }
    }, [loggedIn, dispatch]);

    const RedirectToLogin = ({location}: { location: { pathname: string } }) => {
        useEffect(() => {
            // set the url, where should the user be redirected after login
            dispatch(setLoginRedirect(location.pathname));
        }, [location]);

        return (
            <Redirect
                to={{
                    pathname: `/login`
                }}
            />
        );
    };

    if (loggedIn) {
        return (<>
            {/* private routes and redirect from the login page to the dashboard */}
            <Switch>
                <Route path={"/login"} exact>
                    <Redirect to={{
                        pathname: loginRedirect ?? "/"
                    }}/>
                </Route>
                <Route path={"/reset-password"} exact>
                    <Redirect to={{
                        pathname: loginRedirect ?? "/"
                    }}/>
                </Route>
                <Route component={LoggedInView}/>
            </Switch>
        </>);
    }

    return (
        /* the user is not logged in - allow only the login page and the reset password page,
            everything else is redirected to the login page */
        <Switch>
            <Route path={`/login`} component={LoggedOutView} exact/>
            <Route path={`/reset-password`} component={LoggedOutView} exact/>
            <Route component={RedirectToLogin}/>
        </Switch>
    );
};

// @ts-ignore
const mapStateToProps = ({login, loginRedirect}) => ({
    loggedIn: login?.loggedIn,
    loginRedirect: loginRedirect?.loginRedirect
});

const FinalRouter = memo(connect(mapStateToProps)(LoginLogoutRouter));
export default FinalRouter;