import HttpBackend from "i18next-http-backend";
import httpBackendOptions from "./options/httpBackendOptions";

// for development, use just a plain http backend without caching

const developmentBackendConfig = {
    backends: [
        HttpBackend
    ],
    backendOptions: [
        httpBackendOptions
    ]
};

export default developmentBackendConfig;