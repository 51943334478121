import initialState from "../initialState";
import {SET_LOGIN_REDIRECT, UNSET_LOGIN_REDIRECT} from "../actions";

import StateType from "../StateType";

type Action = {
    type: string,
    pathname?: string
}

const loginRedirectReducer = (state: StateType = initialState, action: Action) => {
    switch (action.type) {
        case SET_LOGIN_REDIRECT:
            return {...state, loginRedirect: action.pathname};
        case UNSET_LOGIN_REDIRECT:
            return {...state, loginRedirect: undefined};
        default:
            return state;
    }
};

export default loginRedirectReducer;