import React, {memo} from "react";
import {
    BrowserRouter
} from "react-router-dom";
import LoginLogoutRouter from "./LoginLogoutRouter";

const RootRouter = memo(() => (
    <BrowserRouter>
        <LoginLogoutRouter/>
    </BrowserRouter>
));

export default RootRouter;