import initialState from "../initialState";
import {LOGIN, LOGOUT} from "../actions";

import StateType from "../StateType";
import {loginUser_loginUser_data} from "../../graphql/mutations/__generated__/loginUser";

type Action = {
    type: string,
    user?: loginUser_loginUser_data
}

const loginReducer = (state: StateType = initialState, action: Action) => {
    switch (action.type) {
        case LOGIN:
            return {...state, loggedIn: true, user: action.user};
        case LOGOUT:
            return {...state, loggedIn: false, user: undefined};
        default:
            return state;
    }
};

export default loginReducer;