import React, {memo} from "react";
import {Block, BlockOverrides} from "baseui/block";
import {RiMapPinLine} from "react-icons/ri";

const pinWrapperOverrides: BlockOverrides = {
    Block: {
        style: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 5,
            pointerEvents: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
};

const pinOverrides: BlockOverrides = {
    Block: {
        style: {
            flex: 0,
            fontSize: '2.5em',
            marginBottom: '0.95em'
        }
    }
};

export default memo<{}>(() => {
    return (
        <Block overrides={pinWrapperOverrides}>
            <Block overrides={pinOverrides}><RiMapPinLine/></Block>
        </Block>
    );
});