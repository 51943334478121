// action types

import {loginUser_loginUser_data} from "../graphql/mutations/__generated__/loginUser";

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT';
export const UNSET_LOGIN_REDIRECT = 'UNSET_LOGIN_REDIRECT';

export const SET_FIREBASE_INSTANCE = 'SET_FIREBASE_INSTACE';
export const SET_FIREBASE_INITIALIZED = 'SET_FIREBASE_INITIALIZED';

// action creators

export const login = (user: loginUser_loginUser_data) => ({
    type: LOGIN,
    user
});

export const logout = () => ({
    type: LOGOUT
});

export const setLoginRedirect = (pathname: string) => ({
    type: SET_LOGIN_REDIRECT,
    pathname
});

export const unsetLoginRedirect = () => ({
    type: UNSET_LOGIN_REDIRECT
});

export const setFirebaseInstance = (firebase: any) => ({
    type: SET_FIREBASE_INSTANCE,
    firebase
});

export const setFirebaseInitialized = () => ({
    type: SET_FIREBASE_INITIALIZED
});