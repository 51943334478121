import {memo} from "react";

import {withStyle} from "baseui";
import {StyledSpinnerNext, SpinnerProps} from "baseui/spinner";

interface LargeSpinnerProps extends SpinnerProps {
    className?: string
}

export default memo<LargeSpinnerProps>(withStyle(StyledSpinnerNext, ({$theme}) => {
    const size = $theme.sizing.scale2400;
    const thickness = $theme.sizing.scale500;
    const primaryColor = $theme.colors.contentTertiary;
    const secondaryColor = $theme.colors.backgroundTertiary;

    return {
        width: size,
        height: size,
        borderLeftWidth: thickness,
        borderRightWidth: thickness,
        borderTopWidth: thickness,
        borderBottomWidth: thickness,
        borderTopColor: primaryColor,
        borderLeftColor: secondaryColor,
        borderRightColor: secondaryColor,
        borderBottomColor: secondaryColor
    }
}));