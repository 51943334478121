import React, {PropsWithChildren, useEffect, memo} from 'react';
import {connect} from "react-redux";

import {setFirebaseInstance, setFirebaseInitialized} from "../redux/actions";
import {firebase} from "./";
import {default as Firebase} from "firebase/app";
import FullPageSpinner from "../components/FullPageSpinner/FullPageSpinner";

interface Props {
    dispatch: Function,
    firebaseInitialized: boolean,
    firebaseUser: Firebase.User | null
}

const FirebaseProvider = memo<Props>(({dispatch, children, firebaseInitialized, firebaseUser}: PropsWithChildren<Props>) => {
    useEffect(() => {
        dispatch(setFirebaseInstance(firebase));

            if (!firebaseInitialized) {
                dispatch(setFirebaseInitialized());
            }

        return () => {
            dispatch(setFirebaseInstance(undefined));
        };
    }, [firebaseInitialized, dispatch, firebaseUser]);

    if (!firebaseInitialized) {
        return <FullPageSpinner/>;
    }

    return <>{children}</>;
});

// @ts-ignore
const mapStateToProps = ({firebase}) => ({
    firebaseInitialized: firebase?.firebaseInitialized,
    firebaseUser: firebase?.firebaseUser
});

export default connect(mapStateToProps)(FirebaseProvider);