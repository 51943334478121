import React, {memo, PropsWithChildren, useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

interface CrosstabLanguageProviderProps {
    currentLanguageCode?: string,
    dispatch: Function
}

const localStorageKey = "appLanguageCode";

/**
 * Listens to the changes in the local i18n language settings and to changes in the localStorage item containing the language code
 *
 * Allows syncing the language across multiple tabs and implements language persistence even when all tabs are closed.
 *
 * This could be as well implemented using "redux to local storage" middleware, but since the language is the only
 * item, that needs to be synchronized across tabs, this is a simpler solution.
 *
 * - If the i18n language changes, triggers a change in the
 * - If the localStorage item changes, updates the i18n language
 */
const CrosstabLanguageProvider = ({children, currentLanguageCode, dispatch}: PropsWithChildren<CrosstabLanguageProviderProps>) => {
    const {i18n} = useTranslation();

    useEffect(() => {
        localStorage.setItem(localStorageKey, i18n.language.toString());
    }, [i18n.language]);

    const onLocalStorageChange = useCallback(async (event: StorageEvent) => {
        if (event.key === localStorageKey && event.newValue && event.newValue !== i18n.language) {
            await i18n.changeLanguage(event.newValue);
        }
    }, [i18n]);

    useEffect(() => {
        window.addEventListener('storage', onLocalStorageChange);

        return () => {
            window.removeEventListener('storage', onLocalStorageChange);
        }
    });

    return (
        <>{children}</>
    )
};

const mapStateToProps = ({currentLanguageCode}: any) => ({
    currentLanguageCode: currentLanguageCode?.currentLanguageCode
});

const FinalCrosstabLanguageProvider = memo(connect(mapStateToProps)(CrosstabLanguageProvider));

export default FinalCrosstabLanguageProvider;