import React, {memo, PropsWithChildren, Suspense, useEffect} from 'react';
import {Provider as ReduxProvider} from "react-redux";

// CSS reset
import 'reset-css';

// global styles
import './global.css';

// Base design framework
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {BaseProvider} from 'baseui';
import {ToasterContainer} from "baseui/toast";
import CustomTheme from './CustomTheme';

// Apollo client
import ApiClient from "./graphql/ApiClient";
import { ApolloProvider } from '@apollo/client';

// Firebase
import {FirebaseProvider} from './firebase';

// redux
import initStore from "./redux/initStore";
import {PersistGate} from 'redux-persist/integration/react'

// local imports
import FullPageSpinner from "./components/FullPageSpinner";
import RootRouter from "./routers/RootRouter";
import getTitle from "./utils/getTitle";
import {MapProvider} from "./components/Map";
import CrosstabLanguageProvider from "./components/CrosstabLanguageProvider";
import {SnackbarProvider} from "baseui/snackbar";
// import LoginProvider from "./components/LoginProvider";

// initialize the styling engine
const engine = new Styletron();

const {store, persistor} = initStore();

const Wrapper = memo(({children}: PropsWithChildren<{}>) => (<div children={children}/>));

function App() {
    useEffect(() => {
        document.title = getTitle();
    }, []);

    return (
        <StyletronProvider value={engine}>
            <ReduxProvider store={store}>
                <PersistGate loading={<FullPageSpinner/>} persistor={persistor}>
                    <FirebaseProvider>
                        <ApolloProvider
                            client={ApiClient}
                        >
                            <BaseProvider theme={CustomTheme}>
                                <ToasterContainer>
                                    <Suspense fallback={<FullPageSpinner/>}>
                                        <CrosstabLanguageProvider>
                                            {/*<LoginProvider>*/}
                                                <MapProvider>
                                                    <SnackbarProvider
                                                        overrides={{PlacementContainer: {style: {zIndex: 100}}}}>
                                                        <Wrapper>
                                                            <RootRouter/>
                                                        </Wrapper>
                                                    </SnackbarProvider>
                                                </MapProvider>
                                            {/*</LoginProvider>*/}
                                        </CrosstabLanguageProvider>
                                    </Suspense>
                                </ToasterContainer>
                            </BaseProvider>
                        </ApolloProvider>
                    </FirebaseProvider>
                </PersistGate>
            </ReduxProvider>
        </StyletronProvider>
    );
}

export default App;
