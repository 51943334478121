import React, {useRef, useState, useEffect, createContext, memo} from "react";
import {BaseLayers} from "react-mapycz";
import {MapProps} from "./Map";
import Block from "../../ui-elements/form-elements/Row";
import CenteredPin from './CenteredPin';
import DisabledOverlay from "./DisabledOverlay";

export const MapContext = createContext<any>(null);

const mapWrapperOverrides = {
    Block: {
        style: {
            position: 'relative'
        }
    }
};

export default memo<MapProps>(({center, onCenterMove, className, displayCenteredPin, disabled}: MapProps) => {
    const mapNode = useRef(null);
    const [map, setMap] = useState(null);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (!map && mapNode) {
            const zoom = 18;
            // @ts-ignore
            const centerCoords = window.SMap.Coords.fromWGS84(center.x, center.y);
            // @ts-ignore
            const sMap = new window.SMap(mapNode.current, centerCoords, zoom);

            const defaultLayer = sMap.addDefaultLayer(BaseLayers.TURIST_NEW);
            defaultLayer.enable();

            sMap.addDefaultControls();

            const signals = sMap.getSignals();

            if (onCenterMove) {
                signals.addListener(window, "map-focus", () => {
                    setIsFocused(true);
                });

                signals.addListener(window, "map-blur", () => {
                    setIsFocused(false);
                });

                // @ts-ignore
                signals.addListener(window, "center-stop", (e) => {
                    onCenterMove(e.target.getCenter());
                });
            }

            setMap(sMap);
        } else if (map && mapNode && !isFocused) {
            // @ts-ignore
            const mapCenter = map.getCenter();
            if (mapCenter.x !== center.x || mapCenter.y !== center.y) {
                // @ts-ignore
                map.setCenter(window.SMap.Coords.fromWGS84(center.x, center.y));
            }
        }
    }, [center, map, mapNode, setMap, onCenterMove, isFocused]);

    return (
        <MapContext.Provider value={map}>
            <Block
                // @ts-ignore
                overrides={mapWrapperOverrides}
            >
                <div className={className} ref={mapNode}/>
                {displayCenteredPin ? <CenteredPin/> : null}
                {disabled ? <DisabledOverlay/> : null}
            </Block>
        </MapContext.Provider>
    )
});