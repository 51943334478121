import {createTheme, lightThemePrimitives} from 'baseui';

const primitives = {
    ...lightThemePrimitives,

    primaryFontFamily: 'Open Sans',

    accent: '#386540'

};

const overrides = {
    typography: {
        DisplayLarge: {
            fontFamily: 'Raleway',
        },
        DisplayMedium: {
            fontFamily: 'Raleway',
        },
        DisplaySmall: {
            fontFamily: 'Raleway',
        },
        DisplayXSmall: {
            fontFamily: 'Raleway',
        },
        HeadingXXLarge: {
            fontFamily: 'Raleway'
        },
        HeadingXLarge: {
            fontFamily: 'Raleway'
        },
        HeadingLarge: {
            fontFamily: 'Raleway'
        },
        HeadingSmall: {
            fontFamily: 'Raleway'
        },
        HeadingXSmall: {
            fontFamily: 'Raleway'
        },
    },

    colors: {
        buttonPrimaryFill: primitives.accent,
        buttonSecondaryFill: primitives.primaryB
    },

    borders: {
        buttonBorderRadius: '25px'
    }
};

const CustomTheme = createTheme(primitives, overrides);

export default CustomTheme;