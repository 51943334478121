import LocalStorageBackend from "i18next-localstorage-backend";
import HttpBackend from "i18next-http-backend";
import localStorageBackendOptions from "./options/localStorageBackendOptions";
import httpBackendOptions from "./options/httpBackendOptions";

const productionBackendConfig = {
    backends: [
        LocalStorageBackend, // translation cache stored in the local storage
        HttpBackend // if the translation is not found in the local storage, use the Http backend
    ],
    backendOptions: [
        localStorageBackendOptions,
        httpBackendOptions
    ]
};

export default productionBackendConfig;