import React, {memo} from "react";
import {Block, BlockOverrides} from "baseui/block";

const disabledOverlayOverrides: BlockOverrides = {
    Block: {
        style: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
};

const DisabledOverlay = memo<{}>(() => {
    return (
        <Block overrides={disabledOverlayOverrides}/>
    );
});

export default DisabledOverlay;